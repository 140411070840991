import emotionalColors from "./emotionalColors";

import { EmotionalLayers } from "types";

const heatmapOpacity = 0.85;
const heatmapWeight = [0, 0, 1, 1];
const heatmapIntensity = 0.5;

// const heatmapRadius = [0, 4, 1, 10]; /* Small */
const heatmapRadius = [0, 15, 1, 25]; /* Medium */
// const heatmapRadius = [0, 10, 1, 45]; /* Big */

const paintLayers: EmotionalLayers = {
  sadness: {
    heatmap: {
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        emotionalColors.sadness[1],
        0.1,
        emotionalColors.sadness[2],
        0.3,
        emotionalColors.sadness[3],
        0.5,
        emotionalColors.sadness[4],
        0.7,
        emotionalColors.sadness[5],
        1,
        emotionalColors.sadness[6]
      ],
      "heatmap-opacity": heatmapOpacity,

      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["get", "sadness"],
        ...heatmapRadius
      ],

      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "sadness"],
        ...heatmapWeight
      ],

      "heatmap-intensity": heatmapIntensity
    },
    circle: {
      "circle-radius": {
        property: "sadness",
        type: "exponential",
        stops: [
          [{ zoom: 7, value: 1 }, 1],
          [{ zoom: 7, value: 6 }, 4],
          [{ zoom: 16, value: 1 }, 5],
          [{ zoom: 16, value: 6 }, 50]
        ]
      },
      "circle-color": {
        property: "sadness",
        type: "exponential",
        stops: [
          [1, "rgba(0, 0, 0,0)"],
          [2, "rgba(0, 0, 0,0)"],
          [3, "rgba(0, 0, 0,0)"],
          [4, "rgba(0, 0, 0,0)"],
          [5, "rgba(0, 0, 0,0)"],
          [6, "rgba(0, 0, 0,0)"]
        ]
      },

      "circle-stroke-width": 0,
      "circle-opacity": {
        stops: [
          [7, 0],
          [8, 1]
        ]
      }
    }
  },
  anger: {
    heatmap: {
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        emotionalColors.anger[1],
        0.1,
        emotionalColors.anger[2],
        0.3,
        emotionalColors.anger[3],
        0.5,
        emotionalColors.anger[4],
        0.7,
        emotionalColors.anger[5],
        1,
        emotionalColors.anger[6]
      ],
      "heatmap-opacity": heatmapOpacity,

      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["get", "anger"],
        ...heatmapRadius
      ],

      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "anger"],
        ...heatmapWeight
      ],

      "heatmap-intensity": heatmapIntensity
    },
    circle: {
      "circle-radius": {
        property: "anger",
        type: "exponential",
        stops: [
          [{ zoom: 7, value: 1 }, 1],
          [{ zoom: 7, value: 6 }, 4],
          [{ zoom: 16, value: 1 }, 5],
          [{ zoom: 16, value: 6 }, 50]
        ]
      },
      "circle-color": {
        property: "anger",
        type: "exponential",
        stops: [
          [1, "rgba(0, 0, 0,0)"],
          [2, "rgba(0, 0, 0,0)"],
          [3, "rgba(0, 0, 0,0)"],
          [4, "rgba(0, 0, 0,0)"],
          [5, "rgba(0, 0, 0,0)"],
          [6, "rgba(0, 0, 0,0)"]
        ]
      },

      "circle-stroke-width": 0,
      "circle-opacity": {
        stops: [
          [7, 0],
          [8, 1]
        ]
      }
    }
  },
  joy: {
    heatmap: {
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        emotionalColors.joy[1],
        0.1,
        emotionalColors.joy[2],
        0.3,
        emotionalColors.joy[3],
        0.5,
        emotionalColors.joy[4],
        0.7,
        emotionalColors.joy[5],
        1,
        emotionalColors.joy[6]
      ],
      "heatmap-opacity": heatmapOpacity,

      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["get", "joy"],
        ...heatmapRadius
      ],

      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "joy"],
        ...heatmapWeight
      ],

      "heatmap-intensity": heatmapIntensity
    },
    circle: {
      "circle-radius": {
        property: "joy",
        type: "exponential",
        stops: [
          [{ zoom: 7, value: 1 }, 1],
          [{ zoom: 7, value: 6 }, 4],
          [{ zoom: 16, value: 1 }, 5],
          [{ zoom: 16, value: 6 }, 50]
        ]
      },
      "circle-color": {
        property: "joy",
        type: "exponential",
        stops: [
          [1, "rgba(0, 0, 0,0)"],
          [2, "rgba(0, 0, 0,0)"],
          [3, "rgba(0, 0, 0,0)"],
          [4, "rgba(0, 0, 0,0)"],
          [5, "rgba(0, 0, 0,0)"],
          [6, "rgba(0, 0, 0,0)"]
        ]
      },

      "circle-stroke-width": 0,
      "circle-opacity": {
        stops: [
          [7, 0],
          [8, 1]
        ]
      }
    }
  },
  disgust: {
    heatmap: {
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        emotionalColors.disgust[1],
        0.1,
        emotionalColors.disgust[2],
        0.3,
        emotionalColors.disgust[3],
        0.5,
        emotionalColors.disgust[4],
        0.7,
        emotionalColors.disgust[5],
        1,
        emotionalColors.disgust[6]
      ],
      "heatmap-opacity": heatmapOpacity,

      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["get", "disgust"],
        ...heatmapRadius
      ],

      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "disgust"],
        ...heatmapWeight
      ],

      "heatmap-intensity": heatmapIntensity
    },
    circle: {
      "circle-radius": {
        property: "disgust",
        type: "exponential",
        stops: [
          [{ zoom: 7, value: 1 }, 1],
          [{ zoom: 7, value: 6 }, 4],
          [{ zoom: 16, value: 1 }, 5],
          [{ zoom: 16, value: 6 }, 50]
        ]
      },
      "circle-color": {
        property: "disgust",
        type: "exponential",
        stops: [
          [1, "rgba(0, 0, 0,0)"],
          [2, "rgba(0, 0, 0,0)"],
          [3, "rgba(0, 0, 0,0)"],
          [4, "rgba(0, 0, 0,0)"],
          [5, "rgba(0, 0, 0,0)"],
          [6, "rgba(0, 0, 0,0)"]
        ]
      },

      "circle-stroke-width": 0,
      "circle-opacity": {
        stops: [
          [7, 0],
          [8, 1]
        ]
      }
    }
  },
  fear: {
    heatmap: {
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        emotionalColors.fear[1],
        0.1,
        emotionalColors.fear[2],
        0.3,
        emotionalColors.fear[3],
        0.5,
        emotionalColors.fear[4],
        0.7,
        emotionalColors.fear[5],
        1,
        emotionalColors.fear[6]
      ],
      "heatmap-opacity": heatmapOpacity,

      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["get", "fear"],
        ...heatmapRadius
      ],

      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "fear"],
        ...heatmapWeight
      ],

      "heatmap-intensity": heatmapIntensity
    },
    circle: {
      "circle-radius": {
        property: "fear",
        type: "exponential",
        stops: [
          [{ zoom: 7, value: 1 }, 1],
          [{ zoom: 7, value: 6 }, 4],
          [{ zoom: 16, value: 1 }, 5],
          [{ zoom: 16, value: 6 }, 50]
        ]
      },
      "circle-color": {
        property: "fear",
        type: "exponential",
        stops: [
          [1, "rgba(0, 0, 0,0)"],
          [2, "rgba(0, 0, 0,0)"],
          [3, "rgba(0, 0, 0,0)"],
          [4, "rgba(0, 0, 0,0)"],
          [5, "rgba(0, 0, 0,0)"],
          [6, "rgba(0, 0, 0,0)"]
        ]
      },

      "circle-stroke-width": 0,
      "circle-opacity": {
        stops: [
          [7, 0],
          [8, 1]
        ]
      }
    }
  },
  surprise: {
    heatmap: {
      "heatmap-color": [
        "interpolate",
        ["linear"],
        ["heatmap-density"],
        0,
        emotionalColors.surprise[1],
        0.1,
        emotionalColors.surprise[2],
        0.3,
        emotionalColors.surprise[3],
        0.5,
        emotionalColors.surprise[4],
        0.7,
        emotionalColors.surprise[5],
        1,
        emotionalColors.surprise[6]
      ],
      "heatmap-opacity": heatmapOpacity,

      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["get", "surprise"],
        ...heatmapRadius
      ],

      "heatmap-weight": [
        "interpolate",
        ["linear"],
        ["get", "surprise"],
        ...heatmapWeight
      ],

      "heatmap-intensity": heatmapIntensity
    },
    circle: {
      "circle-radius": {
        property: "surprise",
        type: "exponential",
        stops: [
          [{ zoom: 7, value: 1 }, 1],
          [{ zoom: 7, value: 6 }, 4],
          [{ zoom: 16, value: 1 }, 5],
          [{ zoom: 16, value: 6 }, 50]
        ]
      },
      "circle-color": {
        property: "surprise",
        type: "exponential",
        stops: [
          [1, "rgba(0, 0, 0,0)"],
          [2, "rgba(0, 0, 0,0)"],
          [3, "rgba(0, 0, 0,0)"],
          [4, "rgba(0, 0, 0,0)"],
          [5, "rgba(0, 0, 0,0)"],
          [6, "rgba(0, 0, 0,0)"]
        ]
      },

      "circle-stroke-width": 0,
      "circle-opacity": {
        stops: [
          [7, 0],
          [8, 1]
        ]
      }
    }
  }
};

export default paintLayers;
