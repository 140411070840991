import React, { useEffect, useMemo } from "react";
import MapGL, {
  Source,
  Layer,
  PointerEvent,
  ViewportProps,
  QueryRenderedFeaturesParams,
  ExtraState
} from "react-map-gl";
import paintLayers from "styles/paintLayers";
import { generateEmotionalData } from "utils";
import { UserResponse, EmotionType } from "types";
import { Feature, Geometry, GeoJsonProperties } from "geojson";

// Defaults
const defaultEmotions: EmotionType[] = [
  "anger",
  "surprise",
  "joy",
  "disgust",
  "sadness",
  "fear"
];
const defaultFilteredEmotions: EmotionType[] = [];
const noop = () => {};

interface MapProps<T extends UserResponse> {
  mapboxToken: string;
  mapRef: React.RefObject<MapGL>;
  viewport: ViewportProps;
  responses: T[];
  emotions?: EmotionType[];
  filteredEmotions?: EmotionType[];
  onMapClick: (response: { results: T[]; event: PointerEvent }) => void;
  onMapMove: (viewport: ViewportProps) => void;
  onMapChange?: (state: ExtraState) => void;
  onMapLoad?: () => void;
  children?: React.ReactNode;
}

const Map = <T extends UserResponse>({
  viewport,
  responses,
  emotions = defaultEmotions,
  filteredEmotions = defaultFilteredEmotions,
  onMapClick,
  onMapMove,
  onMapChange = noop,
  onMapLoad = noop,
  mapboxToken,
  mapRef,
  children
}: MapProps<T>) => {
  const emotionalData = useMemo(
    () => generateEmotionalData(responses, emotions, filteredEmotions),
    [responses, emotions, filteredEmotions]
  );

  /********************** Event Handlers ***********************/

  function handleClick(event: PointerEvent) {
    const map = mapRef?.current;

    if (map) {
      const UserResponsesInAnArea = getResponsesFromMapClick(map, event.point);

      const response = {
        results: UserResponsesInAnArea,
        event: event
      };

      onMapClick(response);
    }
  }

  function getResponsesFromMapClick(
    mapInstance: MapGL,
    points: [number, number]
  ) {
    const [x, y] = points;

    const radius = viewport.zoom / 0.1;

    const bbox: mapboxgl.PointLike[] = [
      [x - radius, y - radius],
      [x + radius, y + radius]
    ];

    const emotionalLayersToQueryMap =
      filteredEmotions.length > 0 ? filteredEmotions : emotions;

    const params = {
      layers: emotionalLayersToQueryMap
    } as QueryRenderedFeaturesParams;

    const features = mapInstance.queryRenderedFeatures(bbox, params);

    return features.map((feature: Feature<Geometry, GeoJsonProperties>) =>
      JSON.parse(feature.properties?.response)
    ) as T[];
  }

  function handleInteractionStateChange(state: ExtraState) {
    onMapChange(state);
  }

  useEffect(() => {
    const mapgl = mapRef?.current;
    const map = mapgl && mapgl.getMap();

    if (map) {
      map.on("idle", onMapLoad);
    }
  }, [mapRef]);

  return (
    <MapGL
      {...viewport}
      minZoom={2}
      maxZoom={12}
      ref={mapRef}
      width="100%"
      height="100%"
      mapStyle="mapbox://styles/mikeour/ck80k4fff2zmc1ipqbdybeoxf"
      onViewportChange={onMapMove}
      onInteractionStateChange={handleInteractionStateChange}
      mapboxApiAccessToken={mapboxToken}
      onNativeClick={handleClick}
    >
      {/* any additional Mapbox components you'd like to render*/}
      {children}

      {/* all of the emotional map layers */}
      {emotions.map((emotion) => (
        <React.Fragment key={emotion}>
          <Source type="geojson" data={emotionalData[emotion]}>
            <Layer
              id={`${emotion}-heat`}
              type="heatmap"
              paint={paintLayers[emotion].heatmap}
            />
          </Source>
          <Source type="geojson" data={emotionalData[emotion]}>
            <Layer
              id={`${emotion}`}
              type="circle"
              paint={paintLayers[emotion].circle}
            />
          </Source>
        </React.Fragment>
      ))}
    </MapGL>
  );
};

export default Map;
