import generateFeature from "./generateFeature";
import { UserResponse, EmotionalData, EmotionType } from "types";

function generateEmotionalData(
  responses: UserResponse[],
  emotions: EmotionType[],
  filteredEmotions: EmotionType[]
) {
  /*

    Step 1: Create the data shape that the map expects.

    If emotions is: ["joy", "surprise", "sadness"]

    Then initialState is: {
      joy: { type: "FeatureCollection", features: [] },
      surprise: { type: "FeatureCollection", features: [] },
      sadness: { type: "FeatureCollection", features: [] },
    };

  */

  const initialState = emotions.reduce(
    (emotionalData: EmotionalData, emotion: EmotionType) => {
      emotionalData[emotion] = { type: "FeatureCollection", features: [] };

      return emotionalData;
    },
    {} as EmotionalData
  );

  /*

    Step 2: Populate the empty emotionalData from Step 1 with the 
      responses, based on the primary emotion.

  */

  const emotionalData = responses.reduce(
    //        accumulator              currentValue
    (emotionalData: EmotionalData, response: UserResponse) => {
      if (
        filteredEmotions.length > 0 &&
        !filteredEmotions.includes(response.primary_emotion)
      ) {
        return emotionalData;
      }

      if (emotionalData.hasOwnProperty(response.primary_emotion)) {
        const feature = generateFeature(response);
        emotionalData[response.primary_emotion].features.push(feature);
      }

      return emotionalData;
    },
    initialState
  );

  /* 

    Step 3: Return the data in the shape that the map can use.

    emotionalData is: {
      surprise: { type: "FeatureCollection", features: [...] },
      joy: { type: "FeatureCollection", features: [...] },
      sadness: { type: "FeatureCollection", features: [...] },
    };

  */

  return emotionalData;
}
export default generateEmotionalData;
