import { UserResponse } from "types";
import { Feature } from "geojson";

function generateFeature(response: UserResponse): Feature {
  return {
    type: "Feature",
    properties: {
      response,
      [response.primary_emotion]:
        response.emotion_scores[response.primary_emotion]
    },
    geometry: {
      type: "Point",
      coordinates: [response.long, response.lat]
    }
  };
}

export default generateFeature;
